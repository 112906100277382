import { Fragment, useState, useEffect, createContext, useContext } from 'react';
import Apoc from './Apoc/Apoc';
import Playground from './Playground';
import {
  getAuth, signInWithEmailAndPassword,
  sendSignInLinkToEmail, isSignInWithEmailLink, signInWithEmailLink,
  signOut, onAuthStateChanged
} from "firebase/auth";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

import './App.css';

require('purecss')

// Use your config values here.
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDiLM4bh9rCi0GVETPLAk_8cbOHDVM4lrk",
  authDomain: "apoc-books-bed22.firebaseapp.com",
  databaseURL: "https://apoc-books-bed22.firebaseio.com",
  projectId: "apoc-books-bed22",
  storageBucket: "apoc-books-bed22.appspot.com",
  messagingSenderId: "418583593479",
  appId: "1:418583593479:web:0011260bb74a68ab7060a2",
  measurementId: "G-JQTXPW3H9K"
};

// Use this to initialize the firebase App
export const firebaseApp = firebase.initializeApp(firebaseConfig);

export let vinciContext = createContext({});

function App() {
  const [user, setUser] = useState(null);
  const [showSigninForm, setShowSigninForm] = useState(false);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    setShowSigninForm(false);
    const auth = getAuth(firebaseApp);
    onAuthStateChanged(auth, (user) => {
      // console.log('inside onAuthSthateChanged - ', { user })
      if (!user) {
        setShowSigninForm(true)
      } else {
        setShowSigninForm(false)
      }
      setUser(user);
    });
  }, [user])

  const signin = async () => {
    if (!user) {
      console.log('Inside signIn')
      const auth = getAuth();
      signInWithEmailAndPassword(auth, email, 'shadow2018')
        .then((userCredential) => {
          // Signed in 
          const user = userCredential.user;
          // ...
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log({ error })
        });
    }
  }

  return (
    <Fragment>
      {showSigninForm ?
        <div>
          <div className="PB-standard-page" style={{ width: '100vw', maxWidth: 700 }}>
            <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
            <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
            <button className="pure-button pure-button-primary" onClick={()=> signin()}>Sign in</button>
            {/*<SigninWithEmail />*/}
          </div>
        </div>
        :
        true ?
          <Apoc user={user} />
          :
          <Playground user={user} />
      }
    </Fragment>
  );
}

export default App;


