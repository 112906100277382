import React from "react";

/*******************************************
* This component is used for wrapping instances
* of subpages relative to a parent page.
*******************************************/
const SubpagesWrapper = (props) => {
  let wrapperStyle = {
    position: 'fixed',
    top: 0, right: 0, bottom: 0, left: 0,
    // pointerEvents: 'none',
    // width: 1, height: 1,
  }
  return (
    <div style={wrapperStyle}>
      {props.children}
    </div>
  )
}
export default SubpagesWrapper;