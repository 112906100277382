import React, { useState, useEffect, createContext, useContext} from 'react';
import { useVinciRouter, useComponentSequencer, usePageSequencer, usePageWrapper, useScreenResizer, useUrlParameters } from '@davinci0428/use-vinci-toolbox';
import Cover from '../Pages/Cover';
import Chapters from '../Pages/Chapters/Chapters';
import SubpagesWrapper from '../Components/SubpagesWrapper';

export let vinciContext = createContext({});
require('purecss');

export const bgColor = "#e6e6e6"

const Apoc = (props) => {

  let screen = useScreenResizer(); // Sets up screen resize listener and returns with and height of screen
  let wrapper = usePageWrapper(700, '100vh', screen.size);

  const vinci = useContext(vinciContext);
  vinci['screen'] = screen;
  vinci['wrapper'] = wrapper;
  // console.log('inside Apoc', {vinci})

  const wrapperStyle = {
    width: wrapper.width,
    height: wrapper.style.height,
    overflowX: 'hidden',
    overflowY: 'visible',
    background: bgColor,
    touchAction: 'none',
    // boxSizing: 'border-box', border: '4px solid violet',
  }

  const [chapterNumber, setChapterNumber] = useState(0);
  // console.log({ chapterNumber })

  return (
    <div style={wrapperStyle}>
      <SubpagesWrapper >
        <Chapters 
          // chapterNumber={chapterNumber} setChapterNumber={setChapterNumber} 
          snippetNumber={chapterNumber} setSnippetNumber={setChapterNumber}
          />
        {/* <Cover /> */}
      </SubpagesWrapper>
    </div>
  );
}
export default Apoc; 