
import { clearIndexedDbPersistence } from 'firebase/firestore';
import React, { useContext, useRef, useState, useEffect } from 'react';
import './snippet_text_formatting.css'

const AddSpanTags = (props) => {
  // console.log('inside addspantags')
  let targetText = props.inputHtml;

  let regexp = new RegExp(/(<.*?>)/gm);
  const myArray = [...targetText.split(regexp)];

  // Helper function adds span tags to single word
  const addSpanTags = (splitArray) => {
    let accum = ''
    splitArray.forEach((word, index) => {
      if (word) {
        accum += `<span>${word} </span>`
      }
    })
    return accum;
  }
  // End helper function

  let [final, setFinal] = useState('');
  let spanTagsRef = useRef(null);
  let spanTagArray = ''
  useEffect(() => {
    myArray.forEach((element, index, array) => {
      let finalResult = ''
      if (element[0] !== '<') {
        let isSplit = element.split(' ');
        let res = addSpanTags(isSplit);
        array[index] = res
      }
      array.forEach((item) => {
        finalResult += item;
      })
      // console.log({finalResult})
      setFinal(finalResult)
    })

  }, [props])

  function createMarkup(content) {
    return { __html: content };
  }

  let strc = spanTagsRef.current
  // console.log('inside AddSpanTags', { props })
  useEffect(() => {
    let spanTags = strc && strc.getElementsByTagName('span');
    // console.log('length', spanTags && spanTags.length)
    if (spanTags != null) {
      // console.log(`position: ${props.position} currentPage: ${props.currentSnippet}`)
      for (let i = 0; i < spanTags.length; ++i) {
        if (props.show) {
          // spanTags[i].style.cssText += 'background: rgba(0,0,0,0);color: rgba(0,0,0,1); transition: 2s';
          // spanTags[i].style.background = 'rgba(0,0,0,0)';
          spanTags[i].style.color = 'rgba(0,0,0,1)';
          // spanTags[i].style.transition = '1.5s'
        } else {
          // spanTags[i].style.cssText = 'background: rgba(230,230,230,1);color: rgba(0,0,0,0); ';
          spanTags[i].style.background = 'rgba(233,233,233,0)';
          spanTags[i].style.color = 'rgba(0,0,0,0)';
          // spanTags[i].style.transition = '2s'
        }
      }
    }
  });
  return (
    <div style={{userSelect: 'none'}} ref={spanTagsRef} dangerouslySetInnerHTML={createMarkup(final)} />
  )
}
export default AddSpanTags;
