import React, { useState, useEffect, useRef, useContext } from 'react';
import { animated, useSpring } from 'react-spring';
import { useDrag, useGesture, useWheel } from '@use-gesture/react'
import { useVinciRouter, useComponentSequencer, usePageSequencer, usePageWrapper, useScreenResizer, useUrlParameters } from '@davinci0428/use-vinci-toolbox';
import SubpagesWrapper from '../../Components/SubpagesWrapper';
import { getFirestore, collection, query, where, doc, setDoc, getDoc, getDocs, onSnapshot, connectFirestoreEmulator } from "firebase/firestore";
import AddSpanTags from './AddSpanTags';
// import { processSnippets } from './functions';
import './Chapters.css';

import { vinciContext } from '../../Apoc/Apoc';

import { bgColor } from '../../Apoc/Apoc';

require('purecss');
let arraySort = require('array-sort');



const Chapters = (props) => {
  const vinci = useContext(vinciContext);
  let screen = useScreenResizer(); // Sets up screen resize listener and returns with and height of screen
  let wrapper = vinci.wrapper; // usePageWrapper(700, '100vh', screen.size);

  const [snippets, setSnippets] = useState([]);

  const [currentChapter, setCurrentChapterId] = useState(0);
  const [currentSnippet, setCurrentSnippet] = useState(0);
  // console.log({currentSnippet})
  const [currentPage, setCurrentPage] = useState(0);
  // console.log({currentPage})

  let [bBoxes, setBboxes] = useState([]);
  let [newSnippetBboxes, setNewSnippetBboxes] = useState([])
  // const [yOffset, setYOffset] = useState(0)
  const [dragOffset, setDragOffset] = useState(0);
  // console.log({ dragOffset })
  const [drag, setDrag] = useState(false);

  let vertPos = bBoxes && bBoxes[currentPage] && bBoxes[currentPage].vertPos
  // if (vertPos) console.log({ vertPos })

  let control = props.chapterNumber
  const { animate, render, setRender } = useComponentSequencer('part', control);

  const [triggered, setTriggered] = useState(false);
  const [prev, setPrev] = useState(false);

  const sectionRef = useRef(null);
  const [vyValue, setVyValue] = useState('');

  let pageTopValue = 0;
  var word = [];

  /* HELPER FUNCTIONS */
  const getSpanTagTopValues = (spanTagArray) => {
    // console.log({spanTagArray})
    let vPosArray = []
    // Get array of BoundingClientRect.top values
    for (let i = 0; i < spanTagArray.length; ++i) {
      let spanTag = spanTagArray && spanTagArray[i] //.innerText;
      // console.log(spanTag.innerText.includes('-'))
      let vPosition = spanTag.getBoundingClientRect().top; // Get top value of one span tag
      //spanTag.addEventListener("click", (e) => console.log({ vPosition }))
      // console.log(vPosition)
      vPosArray.push({ vertHeight: vPosition });
    }
    return vPosArray;
  }

  const processOneSnippet = (spanTagArray) => {
    // Get array of span tag elements
    let vPosArray = getSpanTagTopValues(spanTagArray);
    // Loop through BCR.top values, looking for the bottom of each page. When bottom found, insert page break
    for (let i = 0; i < vPosArray.length; ++i) {
      if (vPosArray[i].vertHeight > (vinci.screen.size.height - 65) + pageTopValue) {
        // BOTTOM FOUND.
        // spanTagArray[i].style.border = '3px solid red'
        spanTagArray[i].style.opacity = 0;
        word && word.push(spanTagArray[i].innerHTML);
        spanTagArray[i].classList.add('pb-pagebreak');
        pageTopValue = vPosArray[i].vertHeight;
      }
    }
  }

  const [probe, setProbe] = useState(false)

  const getIndexValueOfSnippet = (ptv, direction) => {
    newSnippetBboxes.forEach((item, index) => {
      if (item.vertPos === ptv) {
        // console.log('We have a match!');
        if (direction === 'next') {
          setCurrentSnippet(index)
        } else {
          setCurrentSnippet(index - 1)
        }
      }
    })
  }

  const handlePrev = (currentPage) => {
    setProbe(currentPage);
    if (currentPage > 0) {
      setVyValue(0)
      setCurrentPage(currentPage - 1);
      let pageTopValue = vinci.bBoxes[currentPage + 1] && vinci.bBoxes[currentPage + 1].vertPos;
      getIndexValueOfSnippet(pageTopValue, 'prev');
    }
  }
  const handleNext = (currentPage) => {
    setProbe(currentPage);
    setVyValue()
    setCurrentPage(currentPage + 1);
    if (currentPage < bBoxes.length - 1) {
      setCurrentPage(currentPage + 1);
      let pageTopValue = vinci.bBoxes[currentPage + 1] && vinci.bBoxes[currentPage + 1].vertPos;
      getIndexValueOfSnippet(pageTopValue, 'next');
    }
  }
  /* END OF HELPER FUNCTIONS */


  /* DRAG GESTURE PROCESSING */
  let dragThreshold = 1.2;
  useGesture({
    onWheel: ({velocity: [vx, vy], movement: [mx, my],}) => {
      console.log({vy})
      if ((vy > 1) && !triggered) {
        setTriggered(true);

        if (my > 0) handleNext(currentPage);
        if (my < 0) handlePrev(currentPage);
      }
      // console.log(`movement:${my} velocity:${vy} `);
    },
    onWheelEnd: () => setTriggered(false),
    onDrag: ({ down, delta: [dx, dy], velocity: [vx, vy], movement: [mx, my], offset: [ox, oy] }) => {
      setDragOffset(down ? my : 0);
      // setDrag(down ? true : false)
      // console.log({ my })
      if (vy > dragThreshold && !prev) { // defines the drag velocity threshhold
        console.log('speedy!')
        // setVyValue('speedy')
        // setMyLog(my)
        if (my < 0) {
          // setProbe('drag up');
          handleNext(currentPage);
        } else {
          // setProbe('drag down');
          handlePrev(currentPage);
        }
        setPrev(true);
      }
      if (!down) {
        // console.log('stopped')
        setPrev(false);
      }
    },
  },
    {
      target: sectionRef,
      eventOptions: { passive: false },
      rubberbanding: 1
    }
  );


  /**
   * getEpisodeContent( @param {*} none );
   */
  const getEpisodeContent = async () => {
    const firestore = getFirestore();
    const docRef = doc(firestore, "pbook-books", '4wiscazn70mrt0ggkzah'); // Load book data for APOC
    const docSnap = await getDoc(docRef);
    const chapters = docSnap.data().chapterList;
    let chaptersArray = [];
    for (let i = 0; i < chapters.length; ++i) {
      if (chapters[i].visibility === 'show') {
        chaptersArray.push(chapters[i])
      } else {
        // console.log('chapters[i].title', chapters[i].title)
      }
    }

    const getSnippets = async (chapterId) => {
      const q = query(collection(firestore, "pbook-snippets"), where("chapterId", "==", chapterId));
      const querySnapshot = await getDocs(q);
      let i = 0;
      let tempSnippets = []
      querySnapshot.forEach((doc) => {
        if (doc.data()) {
          if (doc.data().visibility === 'show') {
            let data = doc.data();
            tempSnippets.push(data)
            ++i;
          }
        }
      });
      arraySort(tempSnippets, 'position');
      // Adds index property to tempSnippets object if vinsibility is 'show'
      tempSnippets.forEach((item, index) => {
        if (item.visibility === "show") item.position = index;
      })
      return tempSnippets;
    }

    // Puts all the snippets of all the chapters into one array (tempSnippets)
    let tempChapters = [];
    for (let c = 0; c < chaptersArray.length; ++c) {
      let chapterId = chaptersArray[c] && chaptersArray[c].chapterId
      tempChapters.push(await getSnippets(chapterId));
    }
    let tempSnippets = []
    let index = 0;
    for (let p = 0; p < tempChapters.length; ++p) {
      for (let s = 0; s < tempChapters[p].length; ++s) {
        tempChapters[p][s].position = index
        tempSnippets.push(tempChapters[p] && tempChapters[p][s])
        ++index;
      }
    }
    setSnippets(tempSnippets);
    if (tempSnippets.length) console.log({ tempSnippets })
  } // END OF getEpisodeContent()

  useEffect(() => {
    getEpisodeContent(); // Get entire episode
    // console.log({ snippets });
    snippets.forEach((data) => {
      // console.log({ data })
    })


    window.onload = () => {
      console.log('Episode has fully loaded');
      setTimeout(() => {
        let newSnippets = document.getElementsByClassName('pb-new-snippet');
        // console.log({newSnippets})
        // console.log({newSnippets: newSnippets[1].dataset.position})
        for (let i = 0; i < newSnippets.length; ++i) {
          let spanTagArray = newSnippets[i].getElementsByTagName('span');
          let topValue = spanTagArray[0] && spanTagArray[0].getBoundingClientRect().top;
          pageTopValue = topValue;
          processOneSnippet(spanTagArray)
        }
        let pageBreakArray = document.getElementsByClassName('pb-pagebreak');
        console.log({ pageBreakArray })
        for (let y = 0; y < pageBreakArray.length; ++y) {
          pageBreakArray[y].insertAdjacentHTML('afterend', '<span><br /> <span><br /> </span> <span class="pb-snippet"><br /> </span> <span >' + word[y] + '</span> ')
        }
        // Get ALL pb-snippet top values
        let totalPbPages = document.getElementsByClassName('pb-snippet');
        console.log({ totalPbPages })
        // Loop through total pb-snippet elements and get top value for each
        let bBoxArray = [];
        for (let i = 0; i < totalPbPages.length; ++i) {
          // console.log(newSnippets[i].dataset.position)
          bBoxArray.push({
            vertPos: totalPbPages[i].getBoundingClientRect().top,
            // snippetNumber: newSnippets[i] && newSnippets[i].dataset.position
          })
        }
        setBboxes(bBoxArray);
        vinci['bBoxes'] = bBoxArray;
        console.log({ bBoxArray })

        // Page breaks have been inserted in DOM so we need to recalculate the top values of every 'pb-new-snippet' element to be
        // used on the Home page for finding the pages that are new snippets when a Prev or Next button is pushed. For those that are
        // are, (we're still talking about on the Home page) get its index value and save it as the currentSnippet
        let newSnipps = document.getElementsByClassName('pb-new-snippet');
        // console.log({newSnipps})
        let tempArray = [];
        for (let i = 0; i < newSnipps.length; ++i) {
          // console.log({item: newSnipps[i].getBoundingClientRect().top});
          tempArray.push({ vertPos: newSnipps[i].getBoundingClientRect().top })
        }
        console.log({ tempArray })
        // setNewSnippBboxes(tempArray);
        // vinci['newSnippetBboxes'] = tempArray;
        setNewSnippetBboxes(tempArray)
        // console.log({vinci})

        console.log('Finshed processing pages')
      }, 4000);
    };
  }, [props])


  const wrapperStyle = useSpring({
    config: { tension: 190 },
    margin: '0 auto',
    width: wrapper.width,
    height: 'auto',
    overflowX: 'hidden',
    overflowY: 'hidden',
    transform: `translateY(${dragOffset}px)`,
    marginTop: vertPos ? -vertPos : 0,
    touchAction: 'none',
    // boxSizing: 'border-box', border: '4px solid blue',
  });

  let theSnippets = snippets && snippets.map((snip) =>
    <div key={snip.position}>
      <BuildSnippet snippet={snip} currentPage={currentPage} currentSnippet={currentSnippet} drag={drag} vyValue={vyValue} vertPos={vertPos} />
    </div>
  )
  return (
    <animated.div ref={sectionRef} style={wrapperStyle} >
      {/* <div>hey: {vyValue}</div> */}
      PROBE = {probe}<br />
      {theSnippets}
    </animated.div>
  );
}
export default Chapters;


const BuildSnippet = (props) => {
  const vinci = useContext(vinciContext);

  const getHeight = (snippet) => {
    let w = snippet.illustration && snippet.illustration[0].width;
    let h = snippet.illustration && snippet.illustration[0].height;
    let aspectRatio = w / h;
    return vinci.wrapper.width / aspectRatio;
  }

  // let bgColor = '#e6e6e6'
  const buildSnippet = (snippet) => {
    let snippetHTML;
    if (snippet.type === 'narrative') {
      snippetHTML = <div className="pb-snippet" style={{
        padding: '0 35px', textAlign: 'justify', background: bgColor,
      }}>
        <div style={{ marginBottom: 30, paddingTop: 25 }}>
          <AddSpanTags inputHtml={snippet.narrative} show={props.snippet.position <= props.currentSnippet ? true : false} />
        </div>
      </div>
    } else if (snippet.type === 'illustration') {
      snippetHTML = <span className="pb-snippet">
        <Illustration snippet={props.snippet} getHeight={getHeight(props.snippet)}
          show={props.snippet.position <= props.currentSnippet ? true : false} drag={props.drag}
        />
        {snippet.illustration[0] && snippet.illustration[0].narrative ?
          <div style={{ padding: '40px 35px 0 35px', textAlign: 'justify', background: bgColor, marginBottom: 30 }} >
            <AddSpanTags inputHtml={snippet.illustration[0] && snippet.illustration[0].narrative}
              show={props.snippet.position <= props.currentSnippet ? true : false}
            />
          </div>
          : ''
        }
      </span>
    }

    return (
      <div style={{ fontSize: '115%', height: 'auto' }} className="pb-new-snippet" id={props.position} data-position={props.position} >
        VERTICAL POSITION = {props.vertPos}
        {snippetHTML}
      </div>
    )
  }

  return (
    <animated.div >
      {buildSnippet(props.snippet)}
    </animated.div>
  )
}

// const getHeight = (snippet, vinci) => {
//   let w = snippet.illustration && snippet.illustration[0].width;
//   let h = snippet.illustration && snippet.illustration[0].height;
//   let aspectRatio = w / h;
//   return vinci.wrapper.width / aspectRatio;
// }

const Portrait = (props) => {
  const vinci = useContext(vinciContext);
  // console.log({props})

  const landscapeWindow = {
    width: vinci.wrapper && vinci.wrapper.width,
    height: props.imageHeight,
    overflowY: 'auto',
    overflowX: 'hidden',
    touchAction: 'none',
    // boxSizing: 'border-box', border: '2px solid violet'
  }

  let minWidth = vinci.wrapper.width;
  // console.log({minWidth})
  let [imageWidth, setImageWidth] = useState(0);
  // console.log({imageWidth})
  useEffect(() => {
    let imageW = props.imageHeight / props.aspectRatio;
    // console.log('aspectRatio = ', props.aspectRatio)
    if (imageW < vinci.wrapper && vinci.wrapper.width) {
      imageW = minWidth;
    }
    setImageWidth(imageW);
  }, [props])

  let imageOffset = (imageWidth - vinci.wrapper.width) / 2;

  const imageStyle = {
    width: vinci.wrapper.width, // isFinite(imageWidth) ? imageWidth : 0,
    height: vinci.wrapper.width * props.aspectRatio,
    minHeight: 200,
    backgroundImage: `url(${props.url})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: `-${0}px center`,
    backgroundSize: 'cover',
    overflowY: 'auto',
    overflowX: 'hidden',
    touchAction: 'none',
    // boxSizing: 'border-box', border: '1px solid blue'
  }
  return (
    <div style={landscapeWindow}>
      <div style={imageStyle} >
      </div>
    </div>
  )
}

const Landscape = (props) => {
  const vinci = useContext(vinciContext);
  // console.log({props})

  const landscapeWindow = {
    width: vinci.wrapper && vinci.wrapper.width,
    height: props.imageHeight,
    overflowY: 'hidden',
    overflowX: 'auto',
    touchAction: 'none',
    // boxSizing: 'border-box', border: '2px solid violet'
  }

  let minWidth = vinci.wrapper.width;
  // console.log({minWidth})
  let [imageWidth, setImageWidth] = useState(0);
  // console.log({imageWidth})
  useEffect(() => {
    let imageW = props.imageHeight / props.aspectRatio;
    // console.log('aspectRatio = ', props.aspectRatio)
    if (imageW < vinci.wrapper && vinci.wrapper.width) {
      imageW = minWidth;
    }
    setImageWidth(imageW);
  }, [props])

  let imageOffset = (imageWidth - vinci.wrapper.width) / 2;

  const imageStyle = {
    width: isFinite(imageWidth) ? imageWidth : 0,
    height: props.imageHeight,
    minHeight: 200,
    backgroundImage: `url(${props.url})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: `-${0}px center`,
    backgroundSize: 'cover',
    overflowY: 'hidden',
    overflowX: 'auto',
    touchAction: 'none',
    // boxSizing: 'border-box', border: '1px solid blue'
  }
  return (
    <div style={landscapeWindow}>
      <div style={imageStyle} >
      </div>
    </div>
  )
}

const Illustration = (props) => {
  const vinci = useContext(vinciContext);
  const [url, setUrl] = useState('');
  const [aspectRatio, setAspectRatio] = useState(0);

  const imageHeight = vinci.screen.size.height * .70; // Defines the height of the image area for all illustrations

  useEffect(() => {
    setUrl(props.snippet.illustration[0] && props.snippet.illustration[0].url);

    let w = props.snippet.illustration[0].width;
    let h = props.snippet.illustration[0].height;
    setAspectRatio(h / w);
  }, [props]);

  const backgroundTransition = {
    config: { tension: 150 },
    display: 'flex', justifyContent: 'right',
    // width: props.show ? vinci.wrapper.width : vinci.wrapper.width - 70,
    width: vinci.wrapper.width,
    height: imageHeight,
    overflow: 'auto',
    touchAction: 'none',
    // background: '#e7e7e7',
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div style={backgroundTransition} >
        {aspectRatio <= 1 ?
          <Landscape aspectRatio={aspectRatio} imageHeight={imageHeight} url={url} />
          :
          <Portrait aspectRatio={aspectRatio} imageHeight={imageHeight} url={url} />
        }
      </div>
    </div>
  )

}